import React from 'react'
import {
  BlockImage,
  ButtonsWrapper,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './FindYourSpecialist.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../../components/GA'
import { TitleWrapper } from '../Common.styles'

export const FindYourSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Подберите психолога для проработки травмы прямо сейчас!
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Мы предлагаем вам сделать первый шаг на пути к исцелению от
                психотравмы с YouTalk. Наши психологи готовы поддержать вас на
                этом пути, предоставив индивидуальный и профессиональный подход
                к вашей уникальной ситуации. Сделайте выбор в пользу вашего
                благополучия и начните прорабатывать психологическую травму
                прямо сейчас.
              </Text.Large>
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <BlockImage alt="Подберите психолога и запишитесь на консультацию прямо сейчас!" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
