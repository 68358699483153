import React from 'react'
import loveIcon from '../../../../static/img/Pshiholog-v-chate/love-icon.svg'
import messageIcon from '../../../../static/img/Pshiholog-v-chate/message-icon.svg'
import studyIcon from '../../../../static/img/Pshiholog-v-chate/study-icon.svg'
import {
  Content,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './OnlineConsultationTrauma.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const OnlineConsultationTrauma = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2>
                Онлайн-консультация психотерапевта при травме в YouTalk
              </Title.H2>
            </TitleWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={studyIcon} />
              <Text.Large semiBold>
                Все наши психологи — профессионалы, прошедшие строгий отбор. У
                них есть профильное образование и опыт работы с клиентами. Всего
                4% психотерапевтов проходят отбор в нашу команду.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={loveIcon} />
              <Text.Large semiBold>
                В YouTalk вы можете договориться о консультации в любое удобное
                время, так как наши психологи работают в разных часовых поясах.
                Если у вас есть специфические пожелания по времени — например,
                вы можете заниматься только по утрам у воскресенье — почти
                наверняка вы найдете психолога, готового работать в это время.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={messageIcon} />
              <Text.Large semiBold>
                Консультации в YouTalk анонимны. Мы спрашиваем только ваш
                контакт для связи с психотерапевтом и администратором. Вы можете
                оставить при себе всю личную информацию, если захотите.
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
