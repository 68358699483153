import React from 'react'
import {
  CardContainer,
  Content,
  LockImage,
  TitleSection,
  Wrapper
} from './OnlinePsychotherapy.styles'

export const OnlinePsychotherapy = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <LockImage />
          <TitleSection>
            Онлайн-психотерапия предоставляет удобства конфиденциальной терапии
            для тех, кто столкнулся с психологической травмой.
          </TitleSection>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
