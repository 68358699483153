import React from 'react'
import {
  BlockContainer,
  Br,
  ButtonsWrapper,
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  TextAsH3style,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './HeroBlock.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../../components/GA'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'

export const HeroBlock = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <BlockContainer>
            <InfoContainer>
              <TitleWrapper color="#fff">
                <Title.H1>
                  Онлайн психолог,
                  <br /> работающий
                  <Br /> с травмами
                </Title.H1>
              </TitleWrapper>
              <DescriptionWrapper color="#fff">
                <Text.Large semiBold>
                  YouTalk подобрал опытных профессионалов, а с кем начать работу
                  — решаете вы
                </Text.Large>
                <SeoPriceForTitleBlock />
              </DescriptionWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>
            <ImageWrapper>
              <HeroImage alt="Онлайн психолог работающий с травмами" />
            </ImageWrapper>
          </BlockContainer>
          <TextWrapper>
            <TitleWrapper>
              <TextAsH3style>
                Справиться с ментальными проблемами самостоятельно может быть
                непросто, а обратиться за помощью — страшно и стыдно. Но так
                быть не должно!
              </TextAsH3style>
            </TitleWrapper>
            <Text.Large semiBold>
              Профессионал поможет справиться с тяжелыми переживаниями, увидеть
              путь решения проблем и улучшить качество жизни. Подберите
              подходящего личного психолога с помощью YouTalk.
            </Text.Large>
          </TextWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
