import React from 'react'
import {
  BlockImage,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './PsychologicalTraumas.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const PsychologicalTraumas = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <BlockImage alt="Проработка психологических травм онлайн" />
          </ImageWrapper>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>Проработка психологических травм онлайн</Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Эффективность онлайн-терапии подтверждается множеством
                исследований, и становится всё более востребованным инструментом
                в психологической помощи. Особенно это актуально для людей,
                столкнувшихся с последствиями психической травмы.
              </Text.Large>
              <Text.Large semiBold>
                Современные технологии дают нам уникальную возможность
                обратиться за помощью и поддержкой в любое время и в любом
                месте. Онлайн-психотерапия предоставляет удобство консультаций
                на расстоянии, без необходимости покидать дом или офис.
                Независимо от того, где вы находитесь, наша команда
                квалифицированных психологов готова помочь вам справиться с
                негативными последствиями травмы и вернуть гармонию в вашу
                жизнь.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
