import React from 'react'
import { ProductJsonLd } from 'gatsby-plugin-next-seo'
import { useLocation } from '@reach/router'

export const ProductMicromarkupComponent = ({ name }) => {
  const location = useLocation()

  return (
    <ProductJsonLd
      brand="YouTalk"
      images={[`${location.origin}/img/icons/logo.svg`]}
      name={name}
      offers={{
        '@type': 'AggregateOffer',
        priceCurrency: 'RUB',
        lowPrice: '2000',
        highPrice: '10000',
        availability: 'https://schema.org/InStock',
        offerCount: '300'
      }}
    />
  )
}
