import styled from 'styled-components'

import { Button, Title } from '../../../youtalk-storybook/src/ui'
import { size } from '../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
    flex-direction: column;
  }
`

export const TitleWrapper = styled.div`
  flex: 1;
  ${Title.H1} {
    color: ${(props) => props.color ?? '#333'};
  }
  ${Title.H2asH1styles} {
    color: ${(props) => props.color ?? '#333'};
  }
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }

  margin-bottom: 16px;
`

export const DescriptionWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333'};
  margin-bottom: 32px;

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
      margin-bottom: 24px;
    }
  }
`

export const InfoContainer = styled.div``
